#cc-main {
  /** Change font **/
  --cc-font-family: var(--font-roboto);

  /** Change button primary color to turquoise **/
  --cc-btn-primary-bg: #181818;
  --cc-btn-primary-color: #fafafa !important;
  --cc-btn-primary-border-color: #181818 !important;
  --cc-btn-primary-hover-bg: #484848 !important;
  --cc-btn-primary-hover-border-color: #484848;

  --cc-btn-secondary-bg: #eaeff2;

  /** Also make toggles the same color as the button **/
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);

  /** Make the buttons a bit rounder **/
  --cc-btn-border-radius: 0.5rem;

  [data-role="necessary"] {
    background-color: var(--cc-btn-secondary-bg);
    border-color: var(--cc-btn-secondary-border-color);
    color: var(--cc-btn-secondary-color);
  }

  [data-role="necessary"]:hover {
    background-color: var(--cc-btn-secondary-hover-bg);
    border-color: var(--cc-btn-secondary-hover-border-color);
    color: var(--cc-btn-secondary-hover-color);
  }
}
